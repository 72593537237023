import { motion } from 'framer-motion';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useState, useEffect } from 'react';
import PageTemplate from '../components/PageTemplate';
import { 
  AcademicCapIcon,
  UserGroupIcon,
  PuzzlePieceIcon,
  DocumentMagnifyingGlassIcon,
  GlobeAltIcon,
  CodeBracketIcon
} from '@heroicons/react/24/outline';

const TrainingFeature = ({ icon: Icon, title, description }) => (
  <div className="flex flex-col items-center h-full p-6 pt-10 rounded-2xl bg-gradient-to-br from-blue-500/5 to-blue-500/10 dark:from-blue-500/5 dark:to-blue-500/10 backdrop-blur-sm border border-blue-500/10 dark:border-blue-400/10">
    <div className="mb-4 rounded-xl bg-blue-100 dark:bg-blue-900/30 p-4">
      <Icon className="h-8 w-8 text-blue-600 dark:text-blue-400" aria-hidden="true" />
    </div>
    <h3 className="font-['Nunito'] font-semibold text-gray-900 dark:text-white text-xl mb-3 text-center">
      {title}
    </h3>
    <p className="font-['Nunito'] text-base text-gray-600 dark:text-gray-300 leading-relaxed text-center">
      {description}
    </p>
  </div>
);

const Training = () => {
  const { t } = useTranslation();
  const [currentSlide, setCurrentSlide] = useState(0);
  
  const slides = [
    { src: '/workshop1.jpg', alt: 'Corporate training session showing hands-on document verification' },
    { src: '/workshop2.jpg', alt: 'Interactive workshop with participants analyzing documents' },
    { src: '/workshop3.jpg', alt: 'Expert-led training on fraud detection techniques' }
  ];

  // Auto-advance slides
  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentSlide((current) => (current + 1) % slides.length);
    }, 5000); // Change slide every 5 seconds

    return () => clearInterval(timer);
  }, []);

  const nextSlide = () => {
    setCurrentSlide((current) => (current + 1) % slides.length);
  };

  const prevSlide = () => {
    setCurrentSlide((current) => (current - 1 + slides.length) % slides.length);
  };

  return (
    <PageTemplate
      title="Corporate Training"
      description="Expert-led training sessions for document verification and fraud detection"
    >
      {/* Main content section */}
      <div className="max-w-7xl mx-auto">
        {/* Introduction */}
        <div className="prose prose-lg dark:prose-invert max-w-none mb-16">
          <p className="text-xl text-gray-600 dark:text-gray-300 leading-relaxed">
            The staff of VerifyPDF organizes comprehensive corporate training sessions, primarily serving banks, lenders, and insurance companies, while welcoming organizations from all sectors. Our expert-led workshops are designed to empower operational risk teams and fraud detection staff with advanced document verification skills and fraud detection techniques. Available both remotely and on-premise across Europe, South East Asia, Oceania, and North America.
          </p>
        </div>

        {/* Slideshow */}
        <div className="relative w-full aspect-[16/9] mb-16 rounded-2xl overflow-hidden shadow-xl">
          {/* Slides */}
          {slides.map((slide, index) => (
            <motion.div
              key={index}
              initial={{ opacity: 0 }}
              animate={{ opacity: index === currentSlide ? 1 : 0 }}
              transition={{ duration: 0.5 }}
              className="absolute inset-0"
              style={{ display: index === currentSlide ? 'block' : 'none' }}
            >
              <img
                src={slide.src}
                alt={slide.alt}
                className="w-full h-full object-cover"
              />
            </motion.div>
          ))}
          
          {/* Navigation arrows */}
          <button
            onClick={prevSlide}
            className="absolute left-4 top-1/2 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white p-2 rounded-full transition-colors duration-200"
            aria-label="Previous slide"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
            </svg>
          </button>
          <button
            onClick={nextSlide}
            className="absolute right-4 top-1/2 -translate-y-1/2 bg-black/30 hover:bg-black/50 text-white p-2 rounded-full transition-colors duration-200"
            aria-label="Next slide"
          >
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={2} stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
            </svg>
          </button>

          {/* Slide indicators */}
          <div className="absolute bottom-4 left-1/2 -translate-x-1/2 flex space-x-2">
            {slides.map((_, index) => (
              <button
                key={index}
                onClick={() => setCurrentSlide(index)}
                className={`w-2 h-2 rounded-full transition-colors duration-200 ${
                  index === currentSlide ? 'bg-white' : 'bg-white/50'
                }`}
                aria-label={`Go to slide ${index + 1}`}
              />
            ))}
          </div>
        </div>

        {/* Features Grid */}
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8">
          <dl className="grid grid-cols-1 md:grid-cols-3 gap-6">
            <TrainingFeature
              icon={AcademicCapIcon}
              title="Customized Programs"
              description="Training programs tailored to your industry and specific document verification needs."
            />
            <TrainingFeature
              icon={UserGroupIcon}
              title="Expert Trainers"
              description="Learn from experienced professionals with extensive background in document forensics."
            />
            <TrainingFeature
              icon={PuzzlePieceIcon}
              title="Hands-on Practice"
              description="Interactive sessions with real-world examples and practical exercises."
            />
            <TrainingFeature
              icon={DocumentMagnifyingGlassIcon}
              title="Comprehensive Coverage"
              description="From basic verification techniques to advanced fraud detection methods."
            />
            <TrainingFeature
              icon={GlobeAltIcon}
              title="Flexible Delivery"
              description="Choose between remote sessions or on-premise training at your location."
            />
            <TrainingFeature
              icon={CodeBracketIcon}
              title="Technical Deep Dives"
              description="Advanced technical sessions available for development teams with Python experience, covering API integration and custom implementations."
            />
          </dl>
        </div>

        {/* CTA Section */}
        <div className="mx-auto max-w-7xl px-4 sm:px-6 lg:px-8 py-8 sm:py-16">
          <div className="relative isolate overflow-hidden bg-gradient-to-br from-blue-50/80 to-blue-100/50 dark:from-gray-800 dark:to-gray-800/50 px-6 py-12 shadow-xl sm:rounded-3xl sm:px-16 md:pt-16 lg:flex lg:gap-x-20 lg:px-24 lg:py-24">
            <svg
              viewBox="0 0 1024 1024"
              aria-hidden="true"
              className="absolute left-1/2 top-1/2 -z-10 h-[64rem] w-[64rem] -translate-y-1/2 [mask-image:radial-gradient(closest-side,white,transparent)] sm:left-full sm:-ml-80 lg:left-1/2 lg:ml-0 lg:-translate-x-1/2 lg:translate-y-0"
            >
              <circle 
                r={512} 
                cx={512} 
                cy={512} 
                fill="url(#gradient-dark)"
                className="dark:hidden" 
                fillOpacity="0.7" 
              />
              <circle 
                r={512} 
                cx={512} 
                cy={512} 
                fill="url(#gradient-light)"
                className="hidden dark:block" 
                fillOpacity="0.7" 
              />
              <defs>
                <radialGradient id="gradient-dark">
                  <stop stopColor="#3B82F6" />
                  <stop offset={1} stopColor="#60A5FA" />
                </radialGradient>
                <radialGradient id="gradient-light">
                  <stop stopColor="#0EA5E9" />
                  <stop offset={1} stopColor="#38BDF8" />
                </radialGradient>
              </defs>
            </svg>

            <div className="mx-auto max-w-xl text-center lg:mx-0 lg:flex-auto lg:text-left">
              <h2 className="font-['Domine'] text-3xl font-semibold tracking-tight text-gray-900 dark:text-white sm:text-4xl">
                Ready to enhance your team's{' '}
                <span className="text-transparent bg-clip-text bg-gradient-to-r from-blue-600 via-primary-500 to-indigo-500 dark:from-blue-100 dark:via-cyan-400 dark:to-indigo-700">
                  document verification skills?
                </span>
              </h2>
              <p className="mt-6 text-base leading-7 text-gray-600 dark:text-gray-300">
                Our expert trainers are ready to help your operational risk and fraud detection teams master document verification. Schedule a consultation to discuss your training needs and create a customized program for your organization.
              </p>
              <div className="mt-8 flex justify-center lg:justify-start">
                <motion.div
                  whileHover={{ scale: 1.02 }}
                  whileTap={{ scale: 0.98 }}
                >
                  <Link
                    to="/contact"
                    className="inline-flex items-center justify-center rounded-md bg-primary-500 px-6 py-3 text-base font-semibold text-white shadow-sm hover:bg-primary-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary-500 transition-colors duration-200 no-underline"
                  >
                    Organize a consultation
                  </Link>
                </motion.div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageTemplate>
  );
};

export default Training; 