import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import PageTemplate from '../components/PageTemplate';
import { useNavigate } from 'react-router-dom';

const Contact = () => {
  const { t } = useTranslation('common');
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    company: '',
    email: '',
    message: '',
  });
  const [agreed, setAgreed] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [touched, setTouched] = useState({
    email: false
  });
  const [showSuccess, setShowSuccess] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const navigate = useNavigate();

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!isFormValid()) return;

    setSubmitting(true);
    
    try {
      // Primary submission to Web3Forms
      const formDataToSend = new FormData();
      formDataToSend.append('access_key', '446a122a-c743-4116-9c07-815c093c75c7');
      formDataToSend.append('first_name', formData.firstName);
      formDataToSend.append('last_name', formData.lastName);
      formDataToSend.append('company', formData.company);
      formDataToSend.append('email', formData.email);
      formDataToSend.append('message', formData.message);
      formDataToSend.append('botcheck', '');
      formDataToSend.append('subject', 'New Contact Form Submission');

      const response = await fetch('https://api.web3forms.com/submit', {
        method: 'POST',
        body: formDataToSend
      });

      const data = await response.json();

      // Attempt Cloudflare Worker submission as fallback
      try {
        const workerFormData = new FormData();
        const fullName = `${formData.firstName} ${formData.lastName}${formData.company ? ` (${formData.company})` : ''}`;
        
        workerFormData.append('name', fullName);
        workerFormData.append('email', formData.email);
        workerFormData.append('message', formData.message);

        await fetch('https://slack.vivacityholding.com', {
          method: 'POST',
          body: workerFormData
        });
      } catch (workerError) {
        console.error('Cloudflare Worker Error:', workerError);
        // Continue with main flow even if worker fails
      }

      if (data.success) {
        navigate('/message-sent');
      } else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error('Error:', error);
      // Optionally add error handling UI here
    } finally {
      setSubmitting(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
    
    if (name === 'email') {
      setEmailError(!validateEmail(value) && value !== '');
    }
  };

  const handleBlur = (e) => {
    const { name } = e.target;
    setTouched({
      ...touched,
      [name]: true
    });
  };

  const isFormValid = () => {
    return agreed && validateEmail(formData.email);
  };

  return (
    <PageTemplate
      title={t('contact.title')}
      description={t('contact.description')}
    >
      <div className="isolate px-2 py-4 sm:py-8 lg:px-8">
        {/* Decorative background */}
        <div
          aria-hidden="true"
          className="absolute inset-x-0 top-[10rem] -z-10 transform-gpu overflow-hidden blur-3xl sm:top-[5rem]"
        >
          <div
            className="relative left-1/2 -z-10 aspect-[1155/678] w-[36.125rem] max-w-none -translate-x-1/2 rotate-[30deg] bg-gradient-to-tr from-primary-400 to-primary-600 opacity-20 dark:opacity-20 sm:left-[calc(50%-40rem)] sm:w-[72.1875rem]"
            style={{
              clipPath:
                'polygon(74.1% 44.1%, 100% 61.6%, 97.5% 26.9%, 85.5% 0.1%, 80.7% 2%, 72.5% 32.5%, 60.2% 62.4%, 52.4% 68.1%, 47.5% 58.3%, 45.2% 34.5%, 27.5% 76.7%, 0.1% 64.9%, 17.9% 100%, 27.6% 76.8%, 76.1% 97.7%, 74.1% 44.1%)',
            }}
          />
        </div>

        {showSuccess && (
          <div className="mb-4 p-4 bg-green-100 text-green-700 rounded-lg">
            {t('success.messageSent')}
          </div>
        )}

        <form onSubmit={handleSubmit} className="mx-auto mt-4 max-w-xl sm:mt-6">
          {/* Honeypot field for spam protection */}
          <input
            type="checkbox"
            name="botcheck"
            className="hidden"
            style={{ display: 'none' }}
          />

          <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
            <div>
              <label htmlFor="firstName" className="block text-sm font-medium text-gray-900 dark:text-white font-['Nunito']">
                {t('form.firstName')}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="firstName"
                  id="firstName"
                  value={formData.firstName}
                  onChange={handleChange}
                  className="block w-full rounded-lg border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-primary-500 focus:border-transparent outline-none bg-white dark:bg-gray-800 font-['Nunito']"
                />
              </div>
            </div>
            <div>
              <label htmlFor="lastName" className="block text-sm font-medium text-gray-900 dark:text-white font-['Nunito']">
                {t('form.lastName')}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="lastName"
                  id="lastName"
                  value={formData.lastName}
                  onChange={handleChange}
                  className="block w-full rounded-lg border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-primary-500 focus:border-transparent outline-none bg-white dark:bg-gray-800 font-['Nunito']"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="company" className="block text-sm font-medium text-gray-900 dark:text-white font-['Nunito']">
                {t('form.company')}
              </label>
              <div className="mt-2">
                <input
                  type="text"
                  name="company"
                  id="company"
                  value={formData.company}
                  onChange={handleChange}
                  className="block w-full rounded-lg border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-primary-500 focus:border-transparent outline-none bg-white dark:bg-gray-800 font-['Nunito']"
                />
              </div>
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="email" className="block text-sm font-medium text-gray-900 dark:text-white font-['Nunito']">
                {t('form.email')}
              </label>
              <div className="mt-2">
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={`block w-full rounded-lg border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ${
                    emailError && touched.email ? 'ring-red-500' : 'ring-gray-300 dark:ring-gray-700'
                  } placeholder:text-gray-400 focus:ring-2 focus:ring-primary-500 focus:border-transparent outline-none bg-white dark:bg-gray-800 font-['Nunito']`}
                />
              </div>
              {emailError && touched.email && (
                <p className="mt-1 text-sm text-red-500">Please enter a valid email address</p>
              )}
            </div>
            <div className="sm:col-span-2">
              <label htmlFor="message" className="block text-sm font-medium text-gray-900 dark:text-white font-['Nunito']">
                {t('form.message')}
              </label>
              <div className="mt-2">
                <textarea
                  name="message"
                  id="message"
                  rows={4}
                  value={formData.message}
                  onChange={handleChange}
                  className="block w-full rounded-lg border-0 px-4 py-3 text-gray-900 dark:text-white shadow-sm ring-1 ring-inset ring-gray-300 dark:ring-gray-700 placeholder:text-gray-400 focus:ring-2 focus:ring-primary-500 focus:border-transparent outline-none bg-white dark:bg-gray-800 font-['Nunito']"
                />
              </div>
            </div>
            <div className="flex gap-x-4 sm:col-span-2">
              <div className="flex h-6 items-center">
                <input
                  id="privacy-policy"
                  name="privacy-policy"
                  type="checkbox"
                  checked={agreed}
                  onChange={(e) => setAgreed(e.target.checked)}
                  className="h-4 w-4 rounded border-gray-300 text-indigo-600 focus:ring-indigo-600"
                />
              </div>
              <label htmlFor="privacy-policy" className="text-sm text-gray-600">
                {t('form.privacyPolicy')}{' '}
                <a 
                  href="/privacy" 
                  target="_blank" 
                  rel="noopener noreferrer" 
                  className="font-semibold text-indigo-600 hover:text-indigo-500"
                >
                  {t('form.privacyPolicyLink')}
                </a>
                .
              </label>
            </div>
          </div>
          <div className="mt-10">
            <button
              type="submit"
              disabled={!isFormValid() || submitting}
              className="block w-full rounded-md bg-indigo-600 px-3.5 py-2.5 text-center text-sm font-semibold text-white shadow-sm hover:bg-indigo-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600 disabled:bg-gray-600 disabled:cursor-not-allowed"
            >
              {submitting ? t('form.sending') : t('form.send')}
            </button>
          </div>
        </form>
      </div>
    </PageTemplate>
  );
};

export default Contact; 