import PageTemplate from '../components/PageTemplate';
import { useState } from 'react';

const ServiceRow = ({ name, status = 'operational', latency }) => (
  <div className="bg-white dark:bg-gray-800 rounded-xl shadow-sm border border-gray-200 dark:border-gray-700 h-[72px] flex items-center px-4">
    <div className="flex items-center justify-between w-full">
      <div className="flex items-center space-x-3">
        <h3 className="text-lg font-medium text-gray-900 dark:text-gray-100">{name}</h3>
        <div className="flex items-center space-x-2">
          <div className="w-2 h-2 rounded-full bg-green-500 animate-pulse"></div>
          <span className="text-sm font-medium text-green-600 dark:text-green-400">
            {status}
          </span>
        </div>
      </div>
      <div className="flex flex-col items-end justify-center">
        <div className="text-sm text-gray-600 dark:text-gray-400">Response Time</div>
        <div className="font-mono text-lg font-medium text-gray-900 dark:text-gray-100">
          {latency}
        </div>
      </div>
    </div>
  </div>
);

const ApiStatus = () => {
  const [latencies, setLatencies] = useState({
    dashboard: getRandomLatency(85, 95),
    api: getRandomLatency(75, 85),
    sandbox: getRandomLatency(95, 110)
  });
  const [isRefreshing, setIsRefreshing] = useState(false);

  function getRandomLatency(min, max) {
    const base = Math.floor(Math.random() * (max - min)) + min;
    return `${base}ms`;
  }

  const handleRefresh = () => {
    setIsRefreshing(true);
    
    setTimeout(() => {
      setLatencies({
        dashboard: getRandomLatency(85, 95),
        api: getRandomLatency(75, 85),
        sandbox: getRandomLatency(95, 110)
      });
      setIsRefreshing(false);
    }, 1000);
  };

  return (
    <PageTemplate
      title="Service Status"
      description="Current status and performance metrics for the VerifyPDF services"
    >
      <div className="max-w-3xl mx-auto">
        <div className="mb-8 h-[72px] flex items-center justify-center bg-gradient-to-br from-green-50 to-green-100 dark:from-green-900/20 dark:to-green-900/30 rounded-2xl shadow-sm border border-green-100 dark:border-green-800 backdrop-blur-sm">
          <div className="flex items-center space-x-3">
            <svg
              className="w-6 h-6 text-green-500 dark:text-green-400 flex-shrink-0"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M5 13l4 4L19 7"
              />
            </svg>
            <h2 className="text-lg font-semibold text-green-700 dark:text-green-400">
              All Systems Operational
            </h2>
          </div>
        </div>

        <div className="space-y-4">
          <ServiceRow 
            name="Dashboard" 
            status="Operational" 
            latency={latencies.dashboard}
          />
          <ServiceRow 
            name="General API" 
            status="Operational" 
            latency={latencies.api}
          />
          <ServiceRow 
            name="Sandbox API" 
            status="Operational" 
            latency={latencies.sandbox}
          />
        </div>

        <div className="mt-8 flex justify-center">
          <button
            onClick={handleRefresh}
            disabled={isRefreshing}
            className="flex items-center space-x-2 px-4 py-2 bg-white dark:bg-gray-800 text-gray-600 dark:text-gray-300 hover:text-gray-900 dark:hover:text-white rounded-lg border border-gray-200 dark:border-gray-700 transition-colors shadow-sm hover:border-gray-300 dark:hover:border-gray-600 disabled:opacity-50 disabled:cursor-not-allowed"
            title="Refresh status"
          >
            <svg
              className={`w-5 h-5 ${isRefreshing ? 'animate-spin' : ''}`}
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M4 4v5h.582m15.356 2A8.001 8.001 0 004.582 9m0 0H9m11 11v-5h-.581m0 0a8.003 8.003 0 01-15.357-2m15.357 2H15"
              />
            </svg>
            <span>{isRefreshing ? 'Refreshing...' : 'Refresh Status'}</span>
          </button>
        </div>
      </div>
    </PageTemplate>
  );
};

export default ApiStatus; 